<script lang="ts" setup>
const { modals, close, open } = useFunrizeModals();
const route = useRoute();
const isGuest = useIsGuest();

const { openModal, errorMessage, invited_by: invitedBy } = route.query;
const { isFlipTheCoinEnabled } = useFlipTheCoin();
const { isMobile } = useDevice();
useCookiesSetPromoCode();

const dictionary = {
	login: "LazyOModalLogin",
	register: "LazyOModalSignup",
	register1: "LazyOModalSignup"
} as const;
useOtpGetFlow();

onMounted(() => {
	if (!isGuest.value && isFlipTheCoinEnabled.value && openModal === "app-flip-the-coin-modal") {
		open("LazyOModalFlipTheCoin");
	}
	if (!isGuest.value) {
		return;
	}
	if (route.path.includes("register")) {
		open("LazyOModalSignup");
		return;
	}
	if (invitedBy) {
		open("LazyOModalSignup");
	}

	const modal = dictionary[openModal as keyof typeof dictionary];

	if (typeof openModal !== "string" || !modal) {
		return;
	}

	const error = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage;
	const errorString = typeof error === "string" ? error : undefined;

	open(modal, { error: errorString });
});
</script>

<template>
	<div>
		<Teleport to="body">
			<TransitionGroup :name="modals.prevSize === 0 || modals.nextSize === 0 ? 'modal' : 'multiple'" appear>
				<AOverlay
					v-for="[ModalComponent, properties] in modals.items.entries()"
					:id="ModalComponent.replace('LazyO', '')"
					:key="ModalComponent"
					:modifiers="[isMobile ? 'flex-start' : 'flex-center', 'fixed', 'auto']"
					bg-color="var(--china)"
				>
					<component :is="ModalComponent" v-bind="{ onClose: () => close(ModalComponent), ...properties }" />
				</AOverlay>
			</TransitionGroup>
		</Teleport>
	</div>
</template>

<style lang="scss" scoped>
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: var(--o-modals-z-index);

	&#ModalRacesGames {
		z-index: 9997;
	}

	&#ModalEmailConfirm,
	&#ModalPhoneConfirmation,
	&#ModalPhoneConfirmationInfo,
	&#ModalPresetPackage,
	&#ModalWheelDaily,
	&#ModalWheelDailyRules,
	&#ModalWheelLuckySpin,
	&#ModalWheelLuckyRules {
		z-index: 10001;
	}
	&#ModalWelcomeStatusSystem {
		z-index: 10000;
	}

	&#ModalRegistrationComplete,
	&#ModalTermsDialog {
		z-index: 11000;
	}

	&#ModalGame {
		max-height: 100%;
		z-index: 2000;
		cursor: auto;
		top: var(--top-height);

		@include media-breakpoint-down(lg) {
			--top-height: 0;
		}
	}

	&#ModalAccountRealityCheckNotification,
	&#ModalAccountPurchaseLimit,
	&#ModalAccountSelfExclusion {
		align-items: center;
	}
}

.modal-enter-active,
.modal-leave-active {
	transition: opacity 350ms ease-in-out;
}

.modal-enter-from,
.modal-leave-to {
	opacity: 0;
}
</style>
